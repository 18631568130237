'use strict';

/* ----------------------------------------------------------------------------------
スクロールで非表示に
---------------------------------------------------------------------------------- */
export function fadeScrollFunc() {
	let snsLinkElem = '.snsLink';
	let scrPointCount = $(document).scrollTop();
	let ftOffset = $('.footer').position().top;
	let ftHeight = $('.footer').outerHeight();

	// PC時〜タブレット時サイズの時実行（SNSリンクの処理）
	let responsibleCheck = $('#responsibleCheck').width();
	if(responsibleCheck == 1 || responsibleCheck == 1.5) {
		scrPointCount > (ftOffset-ftHeight) ? $(snsLinkElem).fadeOut() : $(snsLinkElem).fadeIn();
	}
}