'use strict';

/* ----------------------------------------------------------------------------------
ページ内リンクのアニメーション
---------------------------------------------------------------------------------- */
export function pageLinkAnimationFunc() {
	$('html').attr('id', 'pageTop');// トップに戻る時に移動する際のフラグID

	$('a[href^="#"]:not(.notScroll)').each(function() {
		const pageUrl = location.href.split('#')[0];
		if(this.href.indexOf(pageUrl + '#') === 0) {
			const id = this.href.split('#')[1];
			$(this).on('click', () => {
				const position = $('#' + id).offset().top;
				$('body,html').animate({scrollTop :position}, 900, 'easeInOutQuart');
				return false;
			});
		}
	});
}