'use strict';

/* ----------------------------------------------------------------------------------
import読み込み
---------------------------------------------------------------------------------- */
// jQueryプラグイン
import 'jquery.easing';

// 共通ページ用
import {versionFunc} from './module/_version';
import {drowerFunc} from './module/_drower';
import {pageLinkAnimationFunc} from './module/_pageLinkAnimation';
import {jsHoverFunc} from './module/_hover';
import {mainVisualFunc} from './module/_mainVisual';
import {loadFunc} from './module/_load';
import {fadeScrollFunc} from './module/_scroll';
//import {snsFunc} from './module/_sns';
//import {heightLineBasicFunc} from './module/_heightLine';
import {scrAniFunc} from './module/_animation';

// トップページ用
import {parallaxFunc,recruitFunc} from './module/_animation';

// 会社概要ページ用
import {googleMapFunc} from './module/_googleMap';

// 制作実績ページ用
import {worksAjaxFunc} from './module/_worksAjax';
import {worksFunc} from './module/_works';

// お問い合わせページ用
import {contactFormFunc,confirmFormFunc} from './module/_form';


/* ----------------------------------------------------------------------------------
通常実行
---------------------------------------------------------------------------------- */
window.addEventListener('DOMContentLoaded', function() {
	// 共通ページ用
	versionFunc();
	drowerFunc();
	pageLinkAnimationFunc();
	jsHoverFunc();
	mainVisualFunc();

	if($('body').hasClass('index')) { // トップページ用
		parallaxFunc();
		recruitFunc();
	} else if($('body').hasClass('aboutUs')) { // 会社概要ページ用
		googleMapFunc();
	} else if($('body').hasClass('works')) { // 制作実績ページ用
		worksAjaxFunc();
        worksFunc();
	} else if($('body').hasClass('contact')) { // お問い合わせページ用
		contactFormFunc();
		confirmFormFunc();
	}
}, false);


/* ----------------------------------------------------------------------------------
window実行
---------------------------------------------------------------------------------- */
$(window).on('load', () => {
	// 共通ページ用
	loadFunc();
});

$(window).on('scroll', () => {
	// 共通ページ用
	fadeScrollFunc();
});


/* ----------------------------------------------------------------------------------
フェードアニメーション実行
---------------------------------------------------------------------------------- */
$(window).on('load scroll', () => {
	// 第1引数にfadeAnime◯◯等の任意のクラス名を入れる
	// 第2引数に.animeFadeIn .show .animeStartいずれかのアニメーションクラス名を入れる
	if($('body').hasClass('index')) { // トップページ用
		// WHO WE ARE?
		scrAniFunc('fadeAnime1','animeFadeIn');
		scrAniFunc('fadeAnime2','animeFadeIn');
		scrAniFunc('fadeAnime3','show');
		scrAniFunc('fadeAnime4','show');
		scrAniFunc('fadeAnime5','show');

		// WORK
		scrAniFunc('fadeAnime6','animeFadeIn');
		scrAniFunc('fadeAnime7','animeFadeIn');
		scrAniFunc('fadeAnime8','show');
		scrAniFunc('fadeAnime9','show');
		scrAniFunc('fadeAnime10','show');
		scrAniFunc('fadeAnime11','show');
		scrAniFunc('fadeAnime12','show');
		scrAniFunc('fadeAnime13','show');
		scrAniFunc('fadeAnime14','show');
		scrAniFunc('fadeAnime15','show');
		scrAniFunc('fadeAnime16','show');
		scrAniFunc('fadeAnime17','show');
		scrAniFunc('fadeAnime18','show');

		// ABOUT US
		scrAniFunc('fadeAnime19','show');
		scrAniFunc('fadeAnime20','show');
		scrAniFunc('fadeAnime21','show');
		scrAniFunc('fadeAnime22','show');
		scrAniFunc('fadeAnime23','show');

		// SERVICE
		scrAniFunc('fadeAnime24','animeFadeIn');
		scrAniFunc('fadeAnime25','animeFadeIn');
		scrAniFunc('fadeAnime26','show');
		scrAniFunc('fadeAnime27','animeFadeIn');
		scrAniFunc('fadeAnime28','show');
		//scrAniFunc('fadeAnime29','animeFadeIn');
		scrAniFunc('fadeAnime30','show');
		//scrAniFunc('fadeAnime31','show');
		//scrAniFunc('fadeAnime32','show');
		//scrAniFunc('fadeAnime33','show');
		//scrAniFunc('fadeAnime34','show');

		// WANT TO WORK WITH US?
		// scrAniFunc('fadeAnime35','show');
		// scrAniFunc('fadeAnime36','show');
		// scrAniFunc('fadeAnime37','show');
		// scrAniFunc('fadeAnime38','show');
		// scrAniFunc('fadeAnime39','show');
	} else if($('body').hasClass('aboutUs')) { // 会社概要ページ用
		scrAniFunc('fadeAnime1','show');
		scrAniFunc('fadeAnime2','show');
		scrAniFunc('fadeAnime3','show');
		scrAniFunc('fadeAnime4','show');
		scrAniFunc('fadeAnime5','show');
		scrAniFunc('fadeAnime6','show');
		scrAniFunc('fadeAnime7','show');
		scrAniFunc('fadeAnime8','show');
		scrAniFunc('fadeAnime9','show');
		scrAniFunc('fadeAnime10','show');
	} else if($('body').hasClass('service')) { // サービスページ用
		scrAniFunc('fadeAnime1','show');
		scrAniFunc('fadeAnime2','show');

		// DIRECTION
		scrAniFunc('fadeAnime3','show');
		scrAniFunc('fadeAnime4','show');
		scrAniFunc('fadeAnime5','animeStart');
		scrAniFunc('fadeAnime6','animeStart');
		scrAniFunc('fadeAnime7','show');

		// DESIGN
		scrAniFunc('fadeAnime8','show');
		scrAniFunc('fadeAnime9','show');
		scrAniFunc('fadeAnime10','animeStart');
		scrAniFunc('fadeAnime11','animeStart');
		scrAniFunc('fadeAnime12','show');

		// PROGRAMMING
		scrAniFunc('fadeAnime13','show');
		scrAniFunc('fadeAnime14','show');
		scrAniFunc('fadeAnime15','animeStart');
		scrAniFunc('fadeAnime16','animeStart');
		scrAniFunc('fadeAnime17','show');

		// SYSTEM/SERVER
		scrAniFunc('fadeAnime18','show');
		scrAniFunc('fadeAnime19','show');
		scrAniFunc('fadeAnime20','animeStart'); 
		scrAniFunc('fadeAnime21','animeStart');
		scrAniFunc('fadeAnime22','show');

		scrAniFunc('fadeAnime23','show');
    } else if($('body').hasClass('works')) { // 実績用
        $('.worksBox').each(function (i) {
            scrAniFunc('fadeAnime' + (i+1),'show');
        });
    }
});