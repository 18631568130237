'use strict';

/* ----------------------------------------------------------------------------------
制作実績
---------------------------------------------------------------------------------- */
import slick from 'slick-carousel';

//TOPのworks一覧にアニメーション用のクラスをつける
// $('.worksPickUpList .worksPickUpBox:eq(0)').addClass('fadeAnime8');
// $('.worksPickUpList .worksPickUpBox:eq(1)').addClass('fadeAnime9');
// $('.worksTopList .worksBox:eq(0)').addClass('fadeAnime10');
// $('.worksTopList .worksBox:eq(1)').addClass('fadeAnime11');
// $('.worksTopList .worksBox:eq(2)').addClass('fadeAnime12');
// $('.worksTopList .worksBox:eq(3)').addClass('fadeAnime13');
// $('.worksTopList .worksBox:eq(4)').addClass('fadeAnime14');
// $('.worksTopList .worksBox:eq(5)').addClass('fadeAnime15');

//タイトルの高さを揃える
// function equalBoxHeight(elm, num) {
//     var elm = $(elm);

//     elm.each(function (i) {
//         var height;
//         var $this = $(this);

//         if (i === 0 || i % num === 0) {
//             height = $this.height();

//             for (var n = i + 1; n <= i + num - 1; n++) {
//                 if (height < elm.eq(n).height()) {
//                     height = elm.eq(n).height();
//                 }
//             }

//             for (var n = i; n <= i + num - 1; n++) {
//                 elm.eq(n).css("height", height + "px");
//             }
//         }
//     });
// }

// const breakPoint = 750;
// if (breakPoint < window.innerWidth) {
//     equalBoxHeight("h3.worksTit", 3);
// } else {
//     equalBoxHeight("h3.worksTit", 1);
// };

// if (breakPoint < window.innerWidth) {
//     equalBoxHeight(".worksArticleOther h3.worksTit", 3);
// }else {
//     equalBoxHeight(".worksArticleOther h3.worksTit", 2);
// };

//works
const worksArea = $('#worksArea').length;
export function worksFunc() {
    if (worksArea) {
        
        $('.worksBox').each(function (i) {
            $(this).addClass('sa saUp fadeAnime' + (i+1));
        });
        
        $('.slideColumn').slick({
            infinite: true,
            dots: false,
            speed: 100,
            slidesToShow: 3, //表示するスライドの数
            slidesToScroll: 1, //スクロールで切り替わるスライドの数
            prevArrow: '<button class="slide-arrow prev-arrow"></button>',
            nextArrow: '<button class="slide-arrow next-arrow"></button>',
            speed: 500,
            
            responsive: [{
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                    centerMode: true, //要素を中央寄せ
                    centerPadding:'45px', //両サイドの見えている部分のサイズ
                }
          }]
        });

        let accordionTxtBtn = $('.moreBtn span')
        let inner = $('.worksContentsAccordionBox')

        accordionTxtBtn.on('click',function(){
            if (inner.not(':animated').length >= 1) {
                inner.stop().slideToggle();
                accordionTxtBtn.stop().toggleClass('close');
                
                if (accordionTxtBtn.hasClass('close')) {
                    accordionTxtBtn.text('CLOSE');
                }else {
                    $(this).text('MORE');
                }
            }

            // const breakPoint = 750;
            // if (breakPoint < window.innerWidth) {
            //     equalBoxHeight("h3.worksTit", 3);
            // } else {
            //     equalBoxHeight("h3.worksTit", 1);
            // };
        });
        
        let numNewspaper = $('.worksContentsBox .worksBox').length;
        if (numNewspaper < 13) {
            accordionTxtBtn.hide();
        };
        
    }
}