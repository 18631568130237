'use strict';

/* ----------------------------------------------------------------------------------
GoogleMapの表示(API利用)
---------------------------------------------------------------------------------- */
export function googleMapFunc() {
	let map;
	let officeMarker;
	let infoWindow;

	// 会社の座標を取得
	let latData = Number($('#gMap').data('lat'));
	let lngData = Number($('#gMap').data('lng'));

	let initMap = () => {
		// 会社の位置情報
		let myLatLng = {lat: latData, lng: lngData};

		// 地図カラー指定
		let styles = [
			{
				"featureType": "all",
				"stylers": [
					{
						"saturation": -150,
						"lightness": 80
					}
				]
			}
		]

		// マップ表示
		map = new google.maps.Map(document.getElementById('gMap'), {
			center: myLatLng,
			zoom: 16,
			styles: styles,
			streetViewControl: false,
			mapTypeControl: false
		});

		// マーカー表示
		let posName = '株式会社iCLIP';
		let image = {
			url: '/images/about_us/icn_marker.svg',
			scaledSize: new google.maps.Size(42.5, 81)
		}
		let officeMarker = new google.maps.Marker({
			map: map,
			title: posName,
			position: myLatLng,
			disableDefaultUI: true,
			optimized: false,
			icon: image
		});
	}
	google.maps.event.addDomListener(window, 'load', initMap);
}