'use strict';

/* ----------------------------------------------------------------------------------
ドロワー制御（ハンバーガーメニューと細かなスクロール処理）
---------------------------------------------------------------------------------- */
export function drowerFunc() {
    // 設定
    const hunElem = '.btnHunArea';
    const drowerArea = '#drawer';
    const hdElem = '.header';
    const ftElem = '.footer';
    const visualElem = '#topVisual';
    const topPath = location.pathname;
    const userAgent = window.navigator.userAgent.toLowerCase();

    let _scrolltop;
    let currentScrollY;
    // 連続クリック防止フラグ
    let timeoutId;
    let animationSpeed = 500; // activeとpassiveクラスのだし分けの速度と同じよう設定する（重要）
    let clickFlgOpen = true;
    let clickFlgClose = true;

    // スライドされる高さの調整
    $(window).on('load resize', () => {
        const windowHeight = $(window).outerHeight();
        $(drowerArea).find('#drawerInner').css({'height':windowHeight}); //高さを出すスクロールを表示
    });

    // メインビジュアルの高さをリアルタイムで処理
    if(topPath == '/') { // トップページのみ判断で処理
        let visualHeight = $(visualElem).height();
        $('.continer').css('margin-top', visualHeight);
        window.addEventListener('resize', function() {
            let visualHeight = $(visualElem).height();
            $('.continer').css('margin-top', visualHeight); 
        });
    }

    // スクロールしたらハンバーガメニュー（同時にトップビジュアルが非表示）が出現
    $(window).on('scroll load resize', () => {
        let scrCount = $(document).scrollTop();
        let displayHeight = $('#displayMenu').offset().top;
        // PC時〜タブレット時サイズの時実行
        let responsibleCheck = $('#responsibleCheck').width();
        if(responsibleCheck == 1 || responsibleCheck == 1.5) {
            if(scrCount > displayHeight) {
                $(hunElem).fadeIn();
            } else {
                $(drowerArea).hasClass('active') ? $(hunElem).fadeIn() : $(hunElem).fadeOut();
            }
        }
        // トップビジュアルの表示操作
        (scrCount > displayHeight) ? $(visualElem).fadeOut() : $(visualElem).fadeIn();
    });

    // ドロワーをオープン
    $(document).on('click','.mainMenu.drawerOpen', function(event) {
        let el = event.currentTarget;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function() {
            if(clickFlgOpen) {
                clickFlgOpen = false;// イベント処理中はフラグをoff

                // アニメーションの種類をブラウザ判定で変更
                if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1 || userAgent.indexOf('edge') != -1) {
                    $(drowerArea).fadeIn();
                } else {
                    $(drowerArea).addClass('active');
                }
                $(el).removeClass('drawerOpen').addClass('drawerClose');
                $(el).find('a span').addClass('iconClose');
                $('#drawerInner').animate({scrollTop :0,'top':'0'}, animationSpeed, 'easeInOutQuart',
                    function() {
                        // 背景を固定（ポジションを取得）
                        currentScrollY = $(window).scrollTop();
                        $('html').css({
                            position: 'fixed',
                            width: '100%',
                            height: '100%',
                            top: -1 * currentScrollY
                        });
                        //アニメーションが終わってから trueにするように指定
                        clickFlgOpen = true;
                    }
                );
            } else {
                // イベント処理中は処理しない
                return false;
            }
        } ,animationSpeed);
    });

    // ドロワーをクローズ
    $(document).on('click','.mainMenu.drawerClose', function(event) {
        let el = event.currentTarget;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function() {
            if(clickFlgClose) {
                clickFlgClose = false; // イベント処理中はフラグをoff

                // アニメーションの種類をブラウザ判定で変更
                if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1 || userAgent.indexOf('edge') != -1) {
                    $(drowerArea).fadeOut();
                } else {
                    $(drowerArea).addClass('passive').removeClass('active');
                }
                // 背景を固定を解除
                $('html').attr({style:''});
                $('html,body').animate({scrollTop:currentScrollY},1);
                $(el).removeClass('drawerClose').addClass('drawerOpen');
                $(el).find('a span').removeClass('iconClose');
                $('#drawerInner').animate({'top':'-500px'}, animationSpeed, 'easeInOutQuart',
                    function() {
                        $(drowerArea).removeClass('passive');
                        //アニメーションが終わってから trueにするように指定
                        clickFlgClose = true;
                    }
                );
            } else {
                // イベント処理中は処理しない
                return false;
            }
        } ,animationSpeed);
    });
}