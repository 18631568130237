'use strict';

/* ----------------------------------------------------------------------------------
ホバー処理（効果はcss側で設定）
---------------------------------------------------------------------------------- */
export function jsHoverFunc() {
	// クラス名「jsHover」をつけると、その要素に対して「hover」クラスが付与される
	$('.jsHover').on('mouseenter touchstart', function() {
		$(this).addClass('hover');
	}).on('mouseleave touchend', function() {
		$(this).removeClass('hover');
	});
}