'use strict';

/* ----------------------------------------------------------------------------------
メインビジュアル制御
---------------------------------------------------------------------------------- */
export function mainVisualFunc() {
	// 設定
	let bgelem = '#bg';
	let mainTxtElem = '.mainTxt';
	let baceElem = '#topVisual';
	let changeElem = 'landChange';
	let mouseElem = '.mouse';
	let topMenuElem = '#topMenu';
	let snsLinkElem = '.snsLink';
	let topPath = location.pathname;

	// デバイスの縦向き、横向きの判定処理
	$(window).on('load orientationchange', function() {
		if(Math.abs(window.orientation) === 90) {
			// 横に傾けた場合
			$(baceElem).find('h1').addClass(changeElem);
			$(baceElem+' '+bgelem).find('p').addClass(changeElem);
			$(baceElem).find(mainTxtElem).addClass(changeElem);
			$(mouseElem).addClass(changeElem);
		} else {
			// 縦に傾けた場合
			$(baceElem).find('h1').removeClass(changeElem);
			$(baceElem+' '+bgelem).find('p').removeClass(changeElem);
			$(baceElem).find(mainTxtElem).removeClass(changeElem);
			$(mouseElem).removeClass(changeElem);
		}
	});

	// トップページと下層ページの処理
	if(topPath == '/') {
		// リアルタイムで高さを取得
		let hSize = $(window).height();
		$(baceElem).css('height', hSize + 'px');
		$(window).on('resize', () => {
			let hSize = $(window).height();
			$(baceElem).css('height', hSize + 'px');
		});
	} else {
		$(snsLinkElem).addClass('under'); // SNSリストにクラスを付与
		$(topMenuElem).addClass('under'); // メニューリストにクラスを付与
	}

	// 上部背景の色を変更
	const bgColorArray = ['bg1','bg2','bg3','bg4'];
	const scaleArray = ['scale1','scale0','scaleX1','scaleX0','scaleY1','scaleY0'];
	let i = 0;
	let bgTimer = setInterval(() => {
		// スケールクラス
		scaleArray.push(scaleArray[i]); // プッシュ
		let scaleClass = scaleArray[i];

		// 背景色
		bgColorArray.push(bgColorArray[i]); // プッシュ
		let bgClass = bgColorArray[i];
		$(baceElem).find(bgelem).removeClass().addClass(scaleClass + ' ' + bgClass);
		i++;
	}, 7000);

	// トップメインフォントをランダムに変更
	let listTagElem = $(bgelem).html();
	$(mainTxtElem).prepend(listTagElem);
	const fontArray = [ // フォントクラスを定義（順番は問わない）
		'fredericka',
		'codystar',
		'caesar',
		'akronim',
		'zilla',
		'gaegu',
		'tangerin',
		'josefin',
		'alegreya',
		'monoton',
		'nemoy'
	];

	const len = $(baceElem).find('span').length;
	for(let i = 0; i <= len; i++) {
		setInterval(function() {
			let randomClass = Math.floor(Math.random() * fontArray.length);
			let fontClassName = fontArray[randomClass];
			// 順にspan要素に対して遅らせながら実行
			$(this).delay(200).queue(function(next) {
				//console.log(i);
				$(baceElem+' '+mainTxtElem).find('span').eq(i).removeClass().addClass(fontClassName);
				$(baceElem+' '+bgelem).find('span').eq(i).removeClass().addClass(fontClassName);
				next();
			});
		}, 5500);
	}
}