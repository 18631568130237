'use strict';

/* ----------------------------------------------------------------------------------
スクロールする度に何らかのアニメーション（任意の要素にクラスを付与）を実行
---------------------------------------------------------------------------------- */
export function scrAniFunc(elem,addClassName) {
	let objPos = $('.'+elem).offset().top;
	//画面のスクロール量と任意要素のスクロール量を計算して実行タイミングを代入
	let scrCount = $(document).scrollTop() + (window.innerHeight/1.2);
	if(scrCount > objPos) $('.'+elem).css({'visibility':'visible'}).addClass(addClassName);
}


/* ----------------------------------------------------------------------------------
パララックス効果
---------------------------------------------------------------------------------- */
export function parallaxFunc() {
	let timeoutId;
	let scrollFrag;

	const throttleEvent = (function() {
		let interval = .1; // 間引き間隔
		let lastTime = new Date().getTime() - interval;
		let whoWeAreaElem = '.whoWeAreArea';
		let worksElem = '.worksArea';
		let aboutUsElem = '.aboutUsArea';
		let serviceElem = '.serviceArea';
		let recruitElem = '.recruitArea';
		let contentsElem = '.contents';
		let stlElem = '.ttl';

		return function() {
			// 最後に実行した時間から間引きたい時間経過していたら実行
			if((lastTime + interval) <= new Date().getTime()) {
				let lastTime = new Date().getTime();
				let y = $(this).scrollTop(); //スクロールの値を取得
				let responsibleCheck = $('#responsibleCheck').width();
				//console.log(responsibleCheck);
				if(responsibleCheck == 1) {
					// PCサイズの時実行（1200px〜）
					// WHO WE ARE?
					$(whoWeAreaElem).find(stlElem).css('top', -((y / 3)-550));
					$(whoWeAreaElem).find('.iclipBox').css('top', -((y / 4)-500));
					
					// WORKS
					$(worksElem).find(stlElem).css('top', -((y / 5.5)-230));
					$(worksElem).find('.worksList').css('margin-top', -((y / 6)-350));

					// ABOUT US
					$(aboutUsElem).find(contentsElem).css('top', -((y / 4)-910));

					// SERVICE
					$(serviceElem).find(contentsElem).css('top', -((y / 3.5)-720));
				} else if(responsibleCheck == 1.5) {
					// タブレットサイズの時実行（768px〜1200px）
					// WHO WE ARE?
					$(whoWeAreaElem).find(stlElem).css('top', -((y / 3)-550));
					$(whoWeAreaElem).find('.iclipBox').css('top', -((y / 4)-500));

					// WORKS
					$(worksElem).find(stlElem).css('top', -((y / 5.5)-230));
					$(worksElem).find('.worksList').css('margin-top', -((y / 6)-350));

					// ABOUT US
					$(aboutUsElem).find(contentsElem).css('top', -((y / 4)-910));

					// SERVICE
					$(serviceElem).find(contentsElem).css('top', -((y / 4)-900));
				} else if(responsibleCheck == 3) {
					// SPサイズの時実行（426px〜767px）
					// WHO WE ARE?
					$(whoWeAreaElem).find(stlElem).css('top', -((y / 4.5)+200));
					$(whoWeAreaElem).find('.iclipBox').css('top', -((y / 3)-180));

					// WORKS
					$(worksElem).find(stlElem).css('top', -((y / 3)-300));
					$(worksElem).find('.worksList').css('margin-top', -((y / 4)-280));

					// ABOUT US
					$(aboutUsElem).find(contentsElem).css('top', -((y / 3)-1250));

					// SERVICE
					$(serviceElem).find(contentsElem).css('top', -((y / 4)-1190));
				} else {
					// SPの時実行（0px〜425px）
					// WHO WE ARE?
					$(whoWeAreaElem).find(stlElem).css('top', -((y / 6)+190));
					$(whoWeAreaElem).find('.iclipBox').css('top', -((y / 3.5)-170));

					// WORKS
					$(worksElem).find(stlElem).css('top', -((y / 3)-300));
					$(worksElem).find('.worksList').css('margin-top', -((y / 4)-280));

					// ABOUT US
					$(aboutUsElem).find(contentsElem).css('top', -((y / 4)-1350));

					// SERVICE
					$(serviceElem).find(contentsElem).css('top', -((y / 4)-1430));
				}
			}
		};
	})();

	// 実行
	if(scrollFrag !== true) {
		scrollFrag = true;
		if(window.addEventListener) {
			window.addEventListener('resize', throttleEvent, false);
			window.addEventListener('scroll', throttleEvent, false);
		} else if(window.attachEvent) {
			window.attachEvent('onresize', throttleEvent);
			window.attachEvent('onscroll', throttleEvent);
		}
	}
}


/* ----------------------------------------------------------------------------------
タグを挿入
---------------------------------------------------------------------------------- */
export function recruitFunc() {
	const recruitElem = $('.photoBox').find('.right').html();
	$('.underExBox').prepend(recruitElem);
}