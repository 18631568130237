'use strict';

/* ----------------------------------------------------------------------------------
各ブラウザ、OSに専用クラスを付与
---------------------------------------------------------------------------------- */
export function versionFunc() {
	// ブラウザ判定
	const ua = navigator.userAgent.toLowerCase();
	if(ua.indexOf('msie') != -1 || ua.indexOf('trident') != -1) {
		// IE11対応（ボタンアクションをなくすため、クラスを外す）
		$('.btnBox').find('a,button').removeClass('btnOutVerticalAnimation');
		$('.btnBox').find('a,button').removeClass('btnRightToAnimation');
		$('.btnBox').find('a,button').removeClass('btnLeftToAnimation');
		$('.btnBox').find('a,button').removeClass('modan');

		// IE閲覧時にクラスを付与
		$('.ieClass').addClass('ieActive');
	}

	// SP、タブレット、PC判定
	const getDevice = (function() {
		var ua = navigator.userAgent;
		if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
			return 'sp';
		} else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
			return 'tab';
		} else {
			return 'pc';
		}
	})();
	if(getDevice == 'tab' || getDevice == 'sp') { // タブレット判定とSP判定
		// ホバーアニメーションのクラスを外す
		$('a').removeClass('jsHover');
		$('.btnBox').find('a').removeClass('btnRightToAnimation');

		// ボタンにクラスを付与
		$('.btnBox').find('a').addClass('spNotHover');
		$('.btnBox').find('button').addClass('spNotHover');
	} else if(getDevice == 'pc') { // PC判定
		// 電話番号リンクの振り分け
		$('a[href^="tel:"]').on('click', (event) => {
			event.preventDefault();
		});
		// クラスを付与（ホバー時下線）
		$('#topMenu').find('a').addClass('pcHover');
		$('#drawerInner').find('a').addClass('pcHover');
	}

	// OS判定
	const windowsU = navigator.platform.indexOf('Win'); // OS Windowsのユーザーエージェント
	if(windowsU != -1) {
		// Windows使用時にクラスを付与
		$('.windowsClass').addClass('windowsActive');
	}
}
