'use strict';

/* ----------------------------------------------------------------------------------
遷移ローディング
---------------------------------------------------------------------------------- */
export function loadFunc() {
	let loading = '#loading';
	let links = 'a.morePage';
	let hiddenElem = '.continer, .opacityHidden'; // ロード時に隠す要素
	let ref = document.referrer; // リファラ情報を得る
	let hereHost = window.location.hostname; // 現在ページのホスト(ドメイン)名を得る

	// ホスト名が含まれるか探す正規表現を作る(大文字・小文字を区別しない)
	let sStr = '^https?://' + hereHost;
	let rExp = new RegExp(sStr, 'i');

	// リファラ文字列を判別
	if(ref.length == 0) {
		// リファラなしの場合
		$(hiddenElem).animate({opacity:1}, 600);// ローディング画面を表示
	} else if(ref.match(rExp)) {
		// マッチした場合＝アクセス元が自サイト内の場合
		$(hiddenElem).animate({opacity:1}, 600);// ローディング画面を表示
	} else {
		// マッチしない場合＝アクセス元がサイト外の場合
		if(hereHost === 'localhost') {
			// 開発環境
			$(hiddenElem).animate({opacity:1}, 600);// ローディング画面を表示
		} else {
			// 本番環境
			$(loading).addClass('isDisplay');
			setTimeout(() => {
				$(loading).removeClass('isDisplay');
				$(hiddenElem).animate({opacity:1}, 600);
			}, 1000);
		}
	}

	$(links).on('click', (event) => {
		event.preventDefault();
		const el = event.currentTarget;

		// 背景を固定（ポジションを取得）
		let currentScrollY = $(window).scrollTop();
		$('html').css({
			position: 'fixed',
			width: '100%',
			height: '100%',
			top: -1 * currentScrollY
		});

		// ローディング画面を隠す
		$(loading).addClass('isDisplay');
		let href = $(el).attr('href');
		setTimeout(() => {
			location.href = href;
		}, 1000);
	});
}