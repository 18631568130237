'use strict';

/* ----------------------------------------------------------------------------------
制作実績Ajax
---------------------------------------------------------------------------------- */
export function worksAjaxFunc() {
	let catSlug;
	let mainUrl = location.href.replace(/works/g, '');
	let ajaxURL = mainUrl + 'wp/wp-admin/admin-ajax.php'; // WordPressでAjaxを使うときの送り先
	
	$('#worksCategorise').find('a').on('click', (event) => {
		const el = event.currentTarget;
		catSlug = $(el).data('cat'); // data-cat属性の値を取得
		let loading = $('#worksLoading');
		let articleOuter = $('#articleOuter');

		let def = $.ajax({
			type:'POST',
			url: ajaxURL,
			cache: false,
			timeout: 3000,
			dataType: 'html',
			data: {
				'action':'my_ajax_get_posts', // 処理を行う関数名
				'slug': catSlug // カテゴリースラッグ変数として渡す
			},
			beforeSend:function() {
				loading.css('display','block');
				articleOuter.html('');
			}
		});
		setTimeout(() => {
			$.when(def).done(function(data, textStatus, jqXHR) {
				// 成功時の処理
				loading.css('display','none');
				articleOuter.html(data); // my_ajax_get_posts関数で生成されたデータ（data）を挿入	
			}).fail(function(jqXHR, textStatus, errorThrown) {
				// エラー時の処理
				loading.css('display','none');
				articleOuter.html('<li class="ajaxErrorTxt">エラー：読み込みに失敗しました。</li>'); // エラー時のメッセージ
			});
		},400);
	});
}