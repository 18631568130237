'use strict';

/* ----------------------------------------------------------------------------------
お問い合わせ
---------------------------------------------------------------------------------- */
export function contactFormFunc() {
    // バリデートテキスト
    const n = '';
    const companyNameRequired = '会社名は入力必須です';
    const nameRequired = 'お名前は入力必須です';
    const lastNameRequired = 'お名前は入力必須です';
    const emailRequired = 'メールアドレスは入力必須です';
    const ermRequired = 'メールアドレスが一致しません';
    const messageRequired = 'お問い合わせ内容は入力必須です';
    const zenkakuRequired = '全角が含まれています';

    // 設定
    let inputBoxArea = '.inputBox';
    let errorTxtElem = '.errorTxt';
    let errorClassName = 'error';
    let submitBtnElem = '.submitBtn';

    /* ----------------------------------------------------------------------------------
    各項目をリアルタイムでチェック
    ---------------------------------------------------------------------------------- */
    // 会社名
    $("input[name='companyName']").blur((event) => {
        if($(event.target).val() === '') {
            if ($(event.target).next(errorTxtElem).text() === '') {
                $(event.target).parent(inputBoxArea).addClass(errorClassName);
                $(event.target).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(companyNameRequired);
            }
        } else {
            $(event.target).parent(inputBoxArea).removeClass(errorClassName);
            $(event.target).removeClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(n); // 何もなければエラー記述を無しにする
        }
    });

    // 担当者名
    $("input[name='firstName']").blur((event) => {
        if($(event.target).val() === '') {
            if ($(event.target).next(errorTxtElem).text() === '') {
                $(event.target).parent(inputBoxArea).addClass(errorClassName);
                $(event.target).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(nameRequired);
            }
        } else {
            $(event.target).parent(inputBoxArea).removeClass(errorClassName);
            $(event.target).removeClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(n); // 何もなければエラー記述を無しにする
        }
    });

    // メールアドレス
    $("[name='email']").blur((event) => {
        if($(event.target).val() === '') {
            if ($(event.target).next(errorTxtElem).text() === '') {
                $(event.target).parent(inputBoxArea).addClass(errorClassName);
                $(event.target).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(emailRequired);
            }
        } else {
            if(!$(event.target).val().match(/[^\x00-\x7E]/)) { // 全角が含まれているとエラー
                // メールアドレスの形式であるかをチェック
                if (!$(event.target).val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
                    $(event.target).parent(inputBoxArea).addClass(errorClassName);
                    $(event.target).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(emailRequired);
                } else {
                    $(event.target).parent(inputBoxArea).removeClass(errorClassName);
                    $(event.target).removeClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(n); // 何もなければエラー記述を無しにする
                }
            } else {
                $(event.target).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(zenkakuRequired);
            }
        }
    });

    // メールアドレス（確認）
    $("input[name='emailConfirm']").blur((event) => {
        if ($(event.target).val() === '') {
            if($(event.target).next(errorTxtElem).text() === '') {
                $(event.target).parent(inputBoxArea).addClass(errorClassName);
                $(event.target).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(emailRequired);
            }
        } else {
            if(!$(event.target).val().match(/[^\x00-\x7E]/)) { // 全角が含まれているとエラー
                // メールアドレスの形式であるかをチェック
                if(!$(event.target).val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
                    $(event.target).parent(inputBoxArea).addClass(errorClassName);
                    $(event.target).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(emailRequired);
                } else {
                    $(event.target).parent(inputBoxArea).removeClass(errorClassName);
                    $(event.target).removeClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(n); // 何もなければエラー記述を無しにする
                }
            } else {
                $(event.target).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(zenkakuRequired);
            }
        }
    });

    // メールアドレスの合致
    $("[name='email'], input[name='emailConfirm']").blur(() => {
        if ($("input[name='email']").val() !== $("input[name='emailConfirm']").val()) {
            if($("input[name='email']").val() !== '' && $("input[name='emailConfirm']").val() !== '') {
                $("input[name='emailConfirm']").parent(inputBoxArea).addClass(errorClassName);
                $("input[name='emailConfirm']").addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(ermRequired);
            }
        } else {
            if($("input[name='email']").val() !== '') {
                $("input[name='emailConfirm']").parent(inputBoxArea).removeClass(errorClassName);
                $("input[name='emailConfirm']").removeClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(n); // 何もなければエラー記述を無しにする
            }
        }
    });

    // お問い合わせ内容
    $("[name='contentInquiry']").blur((event) => {
        if($(event.target).val() === '') {
            if ($(event.target).next(errorTxtElem).text() === '') {
                $(event.target).parent(inputBoxArea).addClass(errorClassName);
                $(event.target).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(messageRequired);
            }
        } else {
            $(event.target).parent(inputBoxArea).removeClass(errorClassName);
            $(event.target).removeClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(n); // 何もなければエラー記述を無しにする
        }
    });

    /* ----------------------------------------------------------------------------------
    途中でENTERキーを阻止
    ---------------------------------------------------------------------------------- */
    $("input[type='text'], input[type='tel'], input[type='email']").keypress((event) => {
        if ((event.which && event.which === 13) || (event.keyCode && event.keyCode === 13)) {
            return false;
        } else {
            return true;
        }
    });

    /* ----------------------------------------------------------------------------------
    各項目をサブミット処理でチェック
    ---------------------------------------------------------------------------------- */
    $('form').submit((event) => {
        // 初期設定
        var set = 0;
        if (set === 0) {
            set = 1;
        } else {
            return false;
        }

        // 会社名
        if($("input[name='companyName']").val() === '') {
            $("input[name='companyName']").parent(inputBoxArea).addClass(errorClassName);
            $("input[name='companyName']").addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(companyNameRequired);
            event.preventDefault();
        }

        // 担当者名
        if($("input[name='firstName']").val() === '') {
            $("input[name='firstName']").parent(inputBoxArea).addClass(errorClassName);
            $("input[name='firstName']").addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(nameRequired);
            event.preventDefault();
        }

        // メールアドレス
        if($("input[name='email']").val() === '') {
            $("input[name='email']").parent(inputBoxArea).addClass(errorClassName);
            $("input[name='email']").addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(emailRequired);
            event.preventDefault();
        } else {
            // メールアドレスの形式であるかをチェック
            if(!$("input[name='email']").val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
                $(this).parent(inputBoxArea).addClass(errorClassName);
                $(this).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(emailRequired);
                event.preventDefault();
            }
        }

        // メールアドレス（確認）
        if($("input[name='emailConfirm']").val() === '') {
            $("input[name='emailConfirm']").parent(inputBoxArea).addClass(errorClassName);
            $("input[name='emailConfirm']").addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(emailRequired);
            event.preventDefault();
        } else {
            //空じゃなかったら
            if (!$("input[name='emailConfirm']").val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
                $(this).parent(inputBoxArea).addClass(errorClassName);
                $(this).addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(ermRequired);
                event.preventDefault();
            }
        }

        // お問い合わせ内容
        if($("[name='contentInquiry']").val() === '') {
            $("[name='contentInquiry']").parent(inputBoxArea).addClass(errorClassName);
            $("[name='contentInquiry']").addClass(errorClassName).parent(inputBoxArea).next(errorTxtElem).text(messageRequired);
            event.preventDefault();
        }

        /* ----------------------------------------------------------------------------------
        エラー箇所があればスクロール
        ---------------------------------------------------------------------------------- */
        let offsetTxt;
        let headerHeight;
        if($(inputBoxArea).hasClass(errorClassName)) {
            // エラー箇所の一番上の方にスクロール
            let target = $('.error').eq(0).offset().top;
            $('html,body').animate({scrollTop: target - 30}, 800); // 位置調整
            return false;
        }

        /* ----------------------------------------------------------------------------------
        エラーがなければ二重送信禁止
        ---------------------------------------------------------------------------------- */
        $(submitBtnElem).find('button').prop('disabled','true'); // disabled付与
        setTimeout(() => {
            $(submitBtnElem).find('button').prop('disabled',''); // disabled解除
        }, 1000);
        return true;
        // 離脱イベントを削除
        //$(window).off('beforeunload', onBeforeunloadHandler);
    });

    /* ----------------------------------------------------------------------------------
    エラー表示時の処理
    ---------------------------------------------------------------------------------- */
    errorTxtElem = $(errorTxtElem).text();
    if(errorTxtElem !== ''){
        $(errorTxtElem).show();
    }

    /* ----------------------------------------------------------------------------------
    離脱を防ぐための記述
    ---------------------------------------------------------------------------------- */
    /*
    $(window).on('beforeunload', () => {
        return '送信が完了していません。このまま移動しますか？';
    });
    $('button[type=submit], .backControl').on('click', () => {
        $(window).off('beforeunload');
    });
    */
}


/* ----------------------------------------------------------------------------------
二重送信防止(確認画面用)
---------------------------------------------------------------------------------- */
export function confirmFormFunc() {
    let confirmFormElem = '#confirmForm';
    $('.twoSingBox .backBtn.disabled').on('click', () => {
        return false;
    });
    $(confirmFormElem).submit((event) => {
        $('.twoSingBox').find('button').prop('disabled','true'); // disabled付与
        $('.twoSingBox .backBtn').addClass('disabled');
        setTimeout(() => {
            $('.twoSingBox').find('button').prop('disabled',''); // disabled解除
            $('.twoSingBox .backBtn').remove('disabled');
        }, 1000);
    });
    $(confirmFormElem).submit((event) => {
        const el = event.currentTarget;
        $(':submit', el).prop('disabled','true');
    });
}
